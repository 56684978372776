import React, {Component} from 'react';
import axios from 'axios';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class AddStaff extends Component {

    constructor(){

        super();

        this.state = ({
            name : '',
            position : '',
            role:''
        });
        
    }

    onFormSubmit = async (e) => {
        e.preventDefault();
       
        const newStaff = {
            name: this.state.name,
            position: this.state.position,
            role: this.state.role
        }

        const response = await axios.post('/admin-panel/staff/add',
            newStaff, 
            { headers: { 'x-auth-token': localStorage.getItem('auth-token')}}
        );
        
        console.log(response.data);
       
        this.setState({
            name : '',
            position : '',
            role: ''
        });
    }

    render(){
        
        return (
            <div>
                <h3>Add a new staf member</h3>

                <Form onSubmit={this.onFormSubmit}>
                    <Form.Row className="align-items-center">
                        <Col sm={3} className="my-1">
                            <Form.Label htmlFor="inlineFormInputName" srOnly>
                                Name
                            </Form.Label>
                            <Form.Control id="inlineFormInputName" placeholder="Name" 
                                value={this.state.name}
                                onChange={(e)=> this.setState({name : e.target.value})} required />
                        </Col>
                        <Col sm={3} className="my-1">
                            <Form.Label htmlFor="inlineFormInputPosition" srOnly>
                                Position
                            </Form.Label>
                            <Form.Control id="inlineFormInputPosition" placeholder="Position"
                                value={this.state.position}
                                onChange={(e)=> this.setState({position : e.target.value})} required />
                        </Col>
                        <Col sm={3} className="my-1">
                            <Form.Label htmlFor="inlineFormInputRole" srOnly>
                                Role
                            </Form.Label>
                            <Form.Control id="inlineFormInputRole" placeholder="Role"
                                value={this.state.role}
                                onChange={(e)=> this.setState({role : e.target.value})}  required />
                        </Col>
                        <Col xs="auto" className="my-1">
                            <Button type="submit">Add</Button>
                        </Col>
                    </Form.Row>
                </Form>
               
            </div>
        )
    }
}

export default AddStaff;