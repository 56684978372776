import React, {Component} from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



const Job = props => (
    <li className="py-3">
        <h6 className="job-title">
            <b>{props.job.title}</b>
        </h6>
        <div dangerouslySetInnerHTML={{ __html: props.job.description }} className="job-description"></div>
    </li>
  )

export default class Jobs extends Component {
    constructor(props){
        super();

        this.state = {jobs: []};
    }

    componentDidMount(){
        
        axios.get('/admin-panel/jobs/all')
            .then(response => {
                //console.log(response.data);
                this.setState({jobs: response.data}); 
                
            })
            .catch(function(err){
                console.log(err);
            });
        
    }

    componentDidUpdate(){
        setTimeout( () => {
            axios.get('/admin-panel/jobs/all')
                .then(response => {
                    //console.log(response.data);
                    this.setState({jobs: response.data});                     
                })
                .catch(function(err){
                    console.log(err);
                });
        }, 1500)
    }

    jobsList() {
        return <ul className="px-3 jobs-list">
          {  this.state.jobs.map(job => {
               return <Job job={job} key={job._id}/>;
             })
          }
        </ul>
      }

    render() {
        return <>
        <Container fluid id="jobs" className="py-5 px-3">
            <Container>
                <Row className="justify-content-center jobs-description py-5">
                    <Col className="text-center">
                        <h1 className="mb-3">Career Opportunities</h1>
                        <p>We have several open roles at Lexington right now please reach out to us at recruiting@lexingtoninnovations.com</p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    {this.jobsList()}
                </Row>
                
                {/* <Row className="justify-content-center jobs-list">
                    <Col lg="7" className="text-left">
                        
                        <h6 className="job-category">
                            <b>
                                AI Developers 
                            </b>
                        </h6>
                        <p></p>
                        <ul className="px-5">
                            <li className="py-3">
                                Text recognition and digitization
                            </li>
                            <li className="py-3">
                                Transaction processing
                            </li>
                            <li className="py-3">
                                Alternative data modelling
                            </li>
                        </ul>
                    </Col>                    
                </Row>
                <Row className="justify-content-center jobs-list">
                    <Col lg="7" className="text-left">
                        <h6 className="job-category">
                            <b>
                                AI Developers 
                            </b>
                        </h6>
                        <p>Multiple roles – can be any combination of these</p>
                        <ul className="px-5">
                            <li className="py-3">
                                Text recognition and digitization
                            </li>
                            <li className="py-3">
                                Transaction processing
                            </li>
                            <li className="py-3">
                                Alternative data modelling
                            </li>
                        </ul>
                    </Col>                    
                </Row> */}
            </Container>
        </Container>
    </>
    }
    
}
