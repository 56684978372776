import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ScrollAnimation from 'react-animate-on-scroll';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';


import Think from '../../assets/images/think-1.png';

export default function Overview() {

    return <div id="overview">
        <Container className="overview-general">
            <Row className="justify-content-center">
                <Col lg xs="10" className="overview-general-text">
                    <Fade>
                        <h2>Lexington Innovations provides custom technology solutions to organizations transacting in the capital markets industry.</h2>
                        <h3>With over 50 years of experience dealing with Canada’s big banks and other financial institutions, our ability to solve the technological challenges of today’s capital markets is simply unmatched.</h3>
                    </Fade>
                </Col>
                
                <Col lg xs="10" className="overview-general-picture">
                    <Reveal effect="fadeInUp" duration={1300}>
                        <img
                            src={Think}
                            className="d-inline-block align-top logo"
                            alt=""
                        />
                    </Reveal>
                    </Col>
                    
            </Row>
        </Container>
        <Container fluid className="overview-detailed">
            
                <Container>
                    <Row className="justify-content-center">
                        <Col xs="11" lg="8" className="overview-detailed-text-wrapper">
                            <h1>Why Lexington Innovations?</h1>
                            <p>    There is no shortage of companies providing consulting to financial organizations. There is also no shortage of companies that provide technologies for the capital markets. However, at Lexington Innovations, we have the unique capacity to match leading-edge technologies to capital-markets needs in a way that is targeted, adaptable and customized.</p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <ScrollAnimation delay="200" duration="1.3" animateIn="fadeInDown" className="col-md col-xs-11 text-block">
                            <i className="fontawesome-icon fa fa-usd circle-yes"></i>
                            <h2>Captial Market Experts</h2>
                            <h6>We have extensive experience adapting currently-available technologies to the specific needs of the capital markets industry.</h6>         
                        </ScrollAnimation>
                        <ScrollAnimation delay="500" duration="1.3" animateIn="fadeInDown" className="col-md col-xs-11 text-block">
                            <i className="fontawesome-icon fa fa-cogs circle-yes"></i>
                            <h2>Leading Technologies</h2>
                            <h6>We’re engaged in high-level strategic partnerships with the top technological providers in the business, including MultiChain, Daisy, and Cirro.</h6>
                        </ScrollAnimation>
                        <ScrollAnimation delay="700" duration="1.3" animateIn="fadeInDown" className="col-md col-xs-11 text-block">
                            <i className="fontawesome-icon fa fa-lightbulb-o circle-yes"></i>
                            <h2>Solutions Providers</h2>
                            <h6>We provide technological solutions that match your capital-markets objectives.</h6>
                        </ScrollAnimation>
                    </Row>
                </Container>
                
            
        </Container>
    </div>
}
