import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Fade from 'react-reveal/Fade';

import block1 from '../../assets/images/lexservices4-292x300.png';
import block2 from '../../assets/images/lexservices1-292x300.png';
import block3 from '../../assets/images/thought-services-1-292x300.png';
import block4 from '../../assets/images/additional-1-292x300.png';

export default function Services() {
    return <>
        <Container fluid id="services">
            <Row>
                <Col lg="6" xs="12" className="services-text-block text-center">
                    <h3>Capital Markets Technology</h3>
                    <img src={block1} className="services-block-image" alt="" />
                    <p>We provide leading-edge technologies to the capital markets, including blockchain, artificial intelligence, data abstraction, and high-performance calculation engines. Our technology selections are driven by our deep understanding of particular business problems. The objective of implementing any technology should always be to drive the business forward.</p>
                </Col>
                <Col lg="6" xs="12" className="services-text-block dark-blue text-center">
                    <h3>Regulatory Adherence</h3>
                    <img src={block2} className="services-block-image" alt="" />
                    <p>With 50 years of experience in capital markets, we know that technology solutions must adhere to the extensive regulatory framework that exists globally, which includes Basel, Dodd-Frank, EMIR, FRTB, etc., and covers all asset classes.</p>
                </Col>
            </Row>
            <Row>
                <Col lg="6" xs="12" className="services-text-block dark-blue text-center">
                    <h3>Experienced Experts/Consultants</h3>
                    <img src={block3} className="services-block-image" alt="" />
                    <p>We don’t just know the technology, we know how to guide banks and financial organizations through the constantly evolving maze of technological change within the capital-markets sectors — and we have a track record of doing just that.</p>
                </Col>
                <Col lg="6" xs="12" className="services-text-block text-center">
                    <h3>Additional Capabilities</h3>
                    <img src={block4} className="services-block-image" alt="" />
                    <p>If there’s a technological need that your organization must meet within the capital-markets industry, Lexington has a solution for you. Such areas of expertise include: risk management, regulatory compliance, artificial intelligence, data analysis, and whatever else comes around the corner in a sector that has changed and will continue to do so.</p>
                </Col>
            </Row>
            <Row className="services-additional">
                <Container>
                    <Col lg="7" xs="10" className="services-additional-text">
                        <h1>Leading-edge innovation, custom solutions</h1>
                        <p>Lexington develops quality relationships with organizations in the capital markets because we deliver technological innovation that meets specific needs.</p>
                    </Col>
                </Container>
            </Row>
        </Container>
    </>
}
