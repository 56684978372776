import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

const Person = props => (
    <tr>
      <td>{props.person.name}</td>
      <td>{props.person.position}</td>
      <td>{props.person.role}</td>
      <td>
        <Link to={"/admin-panel/staff/edit/"+props.person._id}>edit</Link> | <a href="#staff" onClick={() => { props.deletePerson(props.person._id) }}>delete</a>
      </td>
    </tr>
  )

class Staff extends Component{ 

    constructor(props){
        super();

        this.state = {staff: []};
    }

    componentDidMount(){
        
        axios.get('/admin-panel/staff/list')
            .then(response => {
                //console.log(response.data);
                this.setState({staff: response.data});             
            })
            .catch(function(err){
                console.log(err);
            });
    }

    componentDidUpdate(){
        setTimeout( () => {
            axios.get('/admin-panel/staff/list')
            .then(response => {
                //console.log(response.data);
                this.setState({staff: response.data});             
            })
            .catch(function(err){
                console.log(err);
            });
        }, 1500)
        
    }

    deletePerson = id => {
        axios.delete('/admin-panel/staff/'+id, { headers: { 'x-auth-token': localStorage.getItem('auth-token') }})
          .then(response => { console.log(response.data)});
    
        this.setState({
          staff: this.state.staff.filter(el => el._id !== id)
      })
    }

    staffList() {
      return this.state.staff.map(person => {
        return <Person person={person} deletePerson={this.deletePerson} key={person._id}/>;
      })
    }

    render(){
        return <>
            <Row className="py-5 px-3" id="staff">
                <h3>Staff List</h3>
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Position</th>
                            <th>Role</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.staffList() }
                    </tbody>
                </Table>
            </Row>
        </>
    }
}


export default Staff;