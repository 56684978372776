import React, {useState, useEffect} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Axios from 'axios';

import Container from 'react-bootstrap/Container';

import UserContext from "./UserContext";
import Login from './Login';
import Home from './Home';


export default function AdminMain() {

    const [userData, setUserData] = useState({
        token: null,
        user: null,
    });
    
    useEffect(() => { 
        const checkLoggedIn = async () => {
            let token = localStorage.getItem('auth-token');
            if (token === null) {
                localStorage.setItem('auth-token', '');
                token = '';
              }
              const tokenRes = await Axios.post(
                '/users/tokenIsValid',
                null,
                { headers: { 'x-auth-token': token } }
              );
            //console.log(tokenRes.data);

            if (tokenRes.data) {
                const userRes = await Axios.get("/users/", {
                  headers: { "x-auth-token": token },
                });
                //console.log("userRes=", userRes);
                setUserData({
                  token,
                  user: userRes.data,
                });
                
              }
        }
        checkLoggedIn();
    }, [])

    return (
        
        <UserContext.Provider value={{userData, setUserData}}>
            <Container>
                { userData.user ? ( 
                    <Home/> 
                ) : (
                    <Login />
                )
                }
            </Container> 
        </UserContext.Provider>
    )
}
