import React, {useState, useContext} from 'react';
import { useHistory } from "react-router-dom";
import Axios from 'axios';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import UserContext from './UserContext';
import ErrorNotice from './ErrorNotice';
import Logo from '../../assets/images/lexlogo.png';

export default function Login() {

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();

    const { setUserData } = useContext(UserContext);
    const history = useHistory();

    const submit = async (e) => {
        e.preventDefault();
        try {
          const loginUser = { username, password };
          const loginRes = await Axios.post(
            "/users/login",
            loginUser
          );
          console.log(loginRes.data.user);
          setUserData({
            token: loginRes.data.token,
            user: loginRes.data.user,
          });
          localStorage.setItem("auth-token", loginRes.data.token);
          history.push("/admin-panel");
        } catch (err) {
            console.log(err);
          //err.response.data.msg && setError(err.response.data.msg);
        }
      };

    return <>
        <Container>
            <Row className="justify-content-center admin-login">
                <Col lg="6" className="my-auto">
                    <img
                        src={Logo}
                        className="d-block align-top logo my-5"
                        alt="Lexington Innovations logo"
                    />

                    {error && (
                            <ErrorNotice message={error} clearError={() => setError(undefined)} />
                        )}

                    <Form  onSubmit={submit}>
                        <Form.Group controlId="login-username">
                            <Form.Label>Login</Form.Label>
                            <Form.Control type="text" placeholder="Enter username" onChange={(e) => setUsername(e.target.value)} />
                        </Form.Group>

                        <Form.Group controlId="login-password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Sign in
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>        
    </>
}
