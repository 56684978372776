import React, {useState} from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";

import Logo from '../../assets/images/lexlogo.png';


class Header extends React.Component {

    handleSetInactive(to) {
        if (to) {
            document.querySelector(`a[data-href="${to}"]`).classList.remove('active');
        }
        
    }

    render() {
        return  <> 
        
            <Navbar collapseOnSelect sticky="top" className="container-fluid menu-navbar" expand="xl">
                <Container>
                    <Navbar.Brand href="#overview" className="logo-wrapper"><img
                        src={Logo}
                        className="d-inline-block align-top logo"
                        alt="Lexington innovations logo"
                    /></Navbar.Brand>
                
                    <Navbar.Toggle aria-controls="navbar-nav" className="toggle-menu ml-auto"/>
                
                <Navbar.Collapse id="navbar-nav" className="justify-content-end">
                    <Nav className="menu">
                        <Nav.Item>
                            <Nav.Link eventKey="overview" as={Link}
                                data-href="overview"   
                                activeClass="active"
                                to="overview"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onSetInactive={this.handleSetInactive}>
                                Overview
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="2" as={Link}
                                data-href="services"
                                activeClass="active"
                                to="services"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onSetInactive={this.handleSetInactive}>
                                    Services
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="3" as={Link}
                                data-href="our-people"
                                activeClass="active"
                                to="our-people"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onSetInactive={this.handleSetInactive}>
                                Our People
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="4" as={Link}
                                data-href="jobs"
                                activeClass="active"
                                to="jobs"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onSetInactive={this.handleSetInactive}>
                                Jobs
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="5" as={Link}
                                data-href="contact"
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onSetInactive={this.handleSetInactive}>
                                Contact Us
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        
    </>
    }
}
export default Header;
