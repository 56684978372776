import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Fade from 'react-reveal/Fade';
import axios from 'axios';

import Image1 from '../../assets/images/owner1b.png';
import Image2 from '../../assets/images/owner2.png'


const TeamMember = props => (
    <Col lg="4" className="team-name p-5">
        <span className="d-block">{props.person.name}</span>
        <span className="d-block">{props.person.position}</span>
        {/* <span className="d-block">{props.person.role}</span> */}
    </Col>
  )

export default class OurPeople extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          team: []
        }
    }
    
    componentDidMount(){
        
        axios.get('/admin-panel/staff/list')
            .then(response => {
                //console.log(response.data);
                this.setState({team: response.data});             
            })
            .catch(function(err){
                console.log(err);
            });
    }
    
    componentDidUpdate(){
        setTimeout( () => {
            axios.get('/admin-panel/staff/list')
            .then(response => {
                //console.log(response.data);
                this.setState({team: response.data});             
            })
            .catch(function(err){
                console.log(err);
            });
        }, 1500)
    }

    teamList() {
        return <Row className="team-list">
            {  this.state.team.map(person => {
                 return <TeamMember person={person} key={person._id}/>;
               })
            }
        </Row>
    }
    render() {
        return <>
        <div id="our-people">
            <Container>
                <Row className="justify-content-center leadership-text">
                    <Col className="text-center">
                        <h1>Leadership</h1>
                        <p>Lexington’s leadership team, Chamin and Phill, possesses decades of experience delivering technology solutions to banks, financial institutions and other organizations in the capital markets. The partnership works because, together, they fuse technological knowledge with capital-markets expertise in a way that is unique in the industry.</p>
                    </Col>
                </Row>
                <Row className="leadership-people">
                    <Col xs="12" lg="6" className="px-3">
                        <Fade left duration={1500}>
                            <Image src={Image1} roundedCircle fluid className="d-block mx-auto"/>
                            <div className="d-flex justify-content-between px-5 py-4">
                                <div className="leadership-name w-50">
                                    <span className="d-block">Chamin Bellana</span>
                                    <span className="d-block">Managing Director</span>
                                </div>
                                <div className="leadership-social">
                                    <a className="d-block d-lg-inline-block" href="https://ca.linkedin.com/in/chamin-bellana-32773a2"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                    <a className="d-block d-lg-inline-block" href="mailto:cbellana@lexingtoninnovations.com"><i class="fa fa-envelope"></i></a>
                                </div>
                            </div>
                            <div className="leadership-description px-5 text-left">
                                <p>Years of experience: 15+ Chamin is Lexington’s business specialist who guides clients in the capital-markets industry through the technology options available to them.</p>
                            </div>
                        </Fade>
                    </Col>
                    <Col xs="12" lg="6" className="px-3">
                        <Fade right duration={1500}>
                            <Image src={Image2} roundedCircle fluid className="d-block mx-auto"/>
                            <div className="d-flex justify-content-between px-5 py-4">
                                <div className="leadership-name w-50">
                                    <span className="d-block">Phill Moran</span>
                                    <span className="d-block">Managing Director</span>
                                </div>
                                <div className="leadership-social">
                                    <a className="d-block d-lg-inline-block" href="https://ca.linkedin.com/in/phillipmoran"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                    <a className="d-block d-lg-inline-block" href="mailto:pmoran@lexingtoninnovations.com"><i class="fa fa-envelope"></i></a>
                                </div>                           
                            </div>
                            <div className="leadership-description px-5 text-left">
                                <p>Years of experience: 20+ Phill is Lexington’s tech specialist whose expertise lies in developing the technology available to serve the needs of capital-markets institutions.</p>
                            </div>
                        </Fade>
                    </Col>
                </Row>
                <Row className="justify-content-center team-description my-5">
                    <Col className="text-center">
                        <h1>Team</h1>
                        <p className="text-center">A crucial component to Lexington’s ability to deliver targeted solutions to valued clients is the team we have in place that’s dedicated, experienced, and knowledgeable in their field of expertise.</p>
                    </Col>
                </Row>
                <Fade duration={1500}>
                    {/* <Row className="team-list">
                        <Col lg="4" className="team-name p-5">
                            <span className="d-block">David Clair</span>
                            <span className="d-block">Principal</span>
                        </Col>
                        <Col lg="4" className="team-name p-5">
                            <span className="d-block">Bernard Chan</span>
                            <span className="d-block">Principal</span>
                        </Col>
                        <Col lg="4" className="team-name p-5">
                            <span className="d-block">Suraj Gupta</span>
                            <span className="d-block">Senior Consultant</span>
                        </Col>
                    </Row>
                    <Row className="team-list">
                        <Col lg="4" className="team-name p-5">
                            <span className="d-block">Enn Kuutan</span>
                            <span className="d-block">Principal</span>
                        </Col>
                        <Col lg="4" className="team-name p-5">
                            <span className="d-block">Alison Leung</span>
                            <span className="d-block">Senior Consultant</span>
                        </Col>
                        <Col lg="4" className="team-name p-5">
                            <span className="d-block">Narmin Sultanova</span>
                            <span className="d-block">Senior Consultant</span>
                        </Col>
                    </Row>
                    <Row className="team-list">
                        <Col lg="4" className="team-name p-5">
                            <span className="d-block">Radek Guzik</span>
                            <span className="d-block">Principal</span>
                        </Col>
                        <Col lg="4" className="team-name p-5">
                            <span className="d-block">Anton Skourides</span>
                            <span className="d-block">Senior Consultant</span>
                        </Col>
                        <Col lg="4" className="team-name p-5">
                            <span className="d-block">Constantin Lonescu</span>
                            <span className="d-block">Senior Consultant</span>
                        </Col>
                    </Row>
                    <Row className="team-list">
                        <Col lg="4" className="team-name p-5">
                            <span className="d-block">Elchin Karimov</span>
                            <span className="d-block">Consultant</span>
                        </Col>
                    </Row> */}
                    {this.teamList()}
                </Fade>
            </Container>
        </div>
    </>
    }
    
}
