import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import AdminMain from './components/admin/AdminMain';
import Login from './components/admin/Login';
import EditStaff from './components/admin/Staff/EditStaff';
import EditJob from './components/admin/Jobs/EditJob';
import Main from './components/pages/Main';


export default function App() {
    return <>
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Main} />
                <Route exact path="/admin-panel" component={AdminMain} />
                <Route path="/admin-panel/login" component={Login} />
                <Route path="/admin-panel/staff/edit/:id" component={EditStaff} />
                <Route path="/admin-panel/jobs/edit/:id" component={EditJob} />
            </Switch>
        </BrowserRouter>
    </>
}
