import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

const Job = props => (
    <tr>
      <td>{props.job.title}</td>
      <td>
        <Link to={"/admin-panel/jobs/edit/"+props.job._id}>edit</Link> | <a href="#jobPostings" onClick={() => { props.deleteJob(props.job._id) }}>delete</a>
      </td>
    </tr>
  )

class JobPostings extends Component{ 

    constructor(props){
        super();

        this.state = {jobs: []};
    }

    componentDidMount(){        
        axios.get('/admin-panel/jobs/all')
            .then(response => {
                //console.log(response.data);
                this.setState({jobs: response.data});                 
            })
            .catch(function(err){
                console.log(err);
            });        
    }

    componentDidUpdate(){
        setTimeout( () => {
            axios.get('/admin-panel/jobs/all')
                .then(response => {
                    //console.log(response.data);
                    this.setState({jobs: response.data});                     
                })
                .catch(function(err){
                    console.log(err);
                });
        }, 1500)
    }

    deleteJob = id => {
        axios.delete('/admin-panel/jobs/'+id, { headers: { 'x-auth-token': localStorage.getItem('auth-token') }})
          .then(response => { console.log(response.data)});
    
        this.setState({
          jobs: this.state.jobs.filter(el => el._id !== id)
      })
    }

    jobsList() {
      return this.state.jobs.map(job => {
        return <Job job={job} deleteJob={this.deleteJob} key={job._id}/>;
      })
    }

    render(){
        return <>
            <Row className="py-5 px-3" id="jobPostings">
                <h3>Jobs List</h3>
                <Table striped bordered>
                    <thead>
                        <tr>
                        <th>Title</th>
                        <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.jobsList() }
                    </tbody>
                </Table>
            </Row>
        </>
    }
}


export default JobPostings;