import React, { useContext } from "react";
//import {Link} from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";

import UserContext from "./UserContext";
import Logo from '../../assets/images/lexlogo.png';
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";

export default function AdminHeader() {

    const { userData, setUserData } = useContext(UserContext);
    //console.log(userData);
    const logout = () => {
        setUserData({
          token: null,
          user: null,
        });
        localStorage.setItem("auth-token", "");
    };

    const handleSetInactive = (to) => {
        if (to) {
            document.querySelector(`a[data-href="${to}"]`).classList.remove('active');
        }
        
    }

    return <>
        <Navbar className="menu-navbar">
            <Navbar.Brand href="#staff" className="logo-wrapper" expand="xl">
                <img
                    src={Logo}
                    className="d-inline-block align-top logo"
                    alt="Lexington Innovations logo"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-nav" className="toggle-menu ml-auto"/>
            <Navbar.Collapse className="justify-content-end pt-3">
                {/* <Nav.Link as={Link} to="#staff">Staff</Nav.Link> */}
                <Nav className="admin-menu">
                    <Nav.Item>
                        <Nav.Link eventKey="staff" as={Link}
                                    data-href="staff"   
                                    activeClass="active"
                                    to="staff"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    onSetInactive={handleSetInactive}>
                                    Staff
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="jobPostings" as={Link}
                            data-href="jobPostings"   
                            activeClass="active"
                            to="jobPostings"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onSetInactive={handleSetInactive}>
                            Jobs
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Navbar.Text className="px-3">
                    Welcome, <a href="#login">{userData.user.username}</a>
                </Navbar.Text>
                <Button onClick={logout} className="px-3">Log out</Button>
            </Navbar.Collapse>
        </Navbar>
    </>
}
