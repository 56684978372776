import React from 'react';

import Staff from './Staff/Staff';
import AddStaff from './Staff/AddStaff';
import JobPostings from './Jobs/JobPostings';
import AddJob from './Jobs/AddJob';
import AdminHeader from './AdminHeader';

export default function Home() {
    return <>
        <AdminHeader/>
        <Staff />
        <AddStaff />
        <JobPostings />   
        <AddJob />
    </>
}
