import React, { Component } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default class EditJob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      description: ''
    }
  }

  componentDidMount() {
    axios.get('/admin-panel/jobs/' + this.props.match.params.id, { headers: { 'x-auth-token': localStorage.getItem('auth-token')}})
      .then(response => {
        this.setState({
          title: response.data.title,
          description: response.data.description
        })   
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  onSubmit = (e) => {
    e.preventDefault();

    const job = {
      title: this.state.title,
      description: this.state.description
    }

    //console.log(job);

    axios.post('/admin-panel/jobs/update/' + this.props.match.params.id, 
        job, 
        { headers: { 'x-auth-token': localStorage.getItem('auth-token')}}
    )
      .then(res => console.log(res.data));

    window.location = '/admin-panel';
  }

  categoryOptions() {
    return (
      <>
        <Form.Label htmlFor="inlineFormSelectCategory" srOnly>
            Category
        </Form.Label>
        <Form.Control
            as="select"
            id="inlineFormSelectCategory"
            value={this.state.categoryOptions._id}
            onChange={(e)=> this.setState({categoryId : e.target.value})}
        >
            {this.state.categoryOptions.map(category => {
                return <option value={category._id}>{category.title}</option>;
            })}
        </Form.Control>
      </>
    )
  }

  render() {
    return <>
    <Container>
      <Row className="justify-content-center admin-edit-staff">
        <Col className="my-auto">
            <h3>Edit Job </h3>
            <Form onSubmit={this.onSubmit}>
              <Form.Row className="align-items-center">
                <Col sm={10} className="my-3">
                  <Form.Label htmlFor="inlineFormInputName" srOnly>
                      Job title
                  </Form.Label>
                  <Form.Control id="inlineFormInputName" placeholder="Job title" 
                      value={this.state.title}
                      onChange={(e)=> this.setState({title : e.target.value})} required />
                </Col>
                <Col sm={10} className="my-3">
                  <ReactQuill value={this.state.description}
                            onChange={(value) => this.setState({description : value})}  className="editor" required />
                </Col>
                <Col xs={10} className="my-3">
                  <Button type="submit" className="btn btn-primary mr-3">Save</Button>
                  <Button type="button" onClick = {(e) => {e.preventDefault(e); window.location = "/admin-panel"}} className="btn btn-danger mr-3">Cancel</Button>
                </Col>
              </Form.Row>
            </Form>
        </Col>
      </Row>
    </Container>
    </>
  }

}