import React, { Component } from 'react';
import axios from 'axios';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default class EditStaff extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      position: '',
      role: ''
    }
  }

  componentDidMount() {
    axios.get('/admin-panel/staff/'+this.props.match.params.id, { headers: { 'x-auth-token': localStorage.getItem('auth-token')}})
      .then(response => {
        console.log(response.data);
        this.setState({
          name: response.data.name,
          position: response.data.position,
          role: response.data.role
        })   
      })
      .catch(function (error) {
        console.log(error);
      })

  }
  onChangeInput = (e) => {
      this.setState({
        [e.target.name] : e.target.value
      })
  }

  onSubmit = (e) => {
    e.preventDefault();

    const person = {
      name: this.state.name,
      position: this.state.position,
      role: this.state.role
    }

    console.log(person);

    axios.post('/admin-panel/staff/update/' + this.props.match.params.id, 
        person, 
        { headers: { 'x-auth-token': localStorage.getItem('auth-token')}}
    )
      .then(res => console.log(res.data));

    window.location = '/admin-panel';
  }

  render() {
    return <>
    <Container>
      <Row className="justify-content-center admin-edit-staff">
        <Col className="my-auto">
            <h3>Edit Team Member</h3>
            <form onSubmit={this.onSubmit}>
                <div className="form-group"> 
                <label>Name: </label>
                <input  type="text"
                    required
                    className="form-control"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChangeInput}
                    />
                </div>
                <div className="form-group">
                <label>Position: </label>
                <input 
                    type="text" 
                    required
                    className="form-control"
                    name="position"
                    value={this.state.position}
                    onChange={this.onChangeInput}
                    />
                </div>
                <div className="form-group">
                <label>Role: </label>
                <input 
                    type="text" 
                    required
                    className="form-control"
                    name="role"
                    value={this.state.role}
                    onChange={this.onChangeInput}
                    />
                </div>
                <div className="form-group">
                  <Button type="submit" className="btn btn-primary mr-3">Save</Button>
                  <Button type="button" onClick = {(e) => {e.preventDefault(e); window.location = "/admin-panel"}} className="btn btn-danger mr-3">Cancel</Button>
                </div>
            </form>
        </Col>
      </Row>
    </Container>
    </>
  }

}