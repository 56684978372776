import React, {useState} from 'react';

import Header from './Header';
import Overview from './Overview';
import Services from './Services';
import OurPeople from './OurPeople';
import Jobs from './Jobs';
import Contact from './Contact';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';

import '../../assets/css/style.css';
import '../../assets/css/font-awesome.min.css';

export default function Main() {

    return <>
        <ScrollToTop></ScrollToTop>
        <Header></Header>
        <Overview></Overview>
        <Services></Services>
        <OurPeople></OurPeople>
        <Jobs></Jobs>
        <Contact></Contact>
        <Footer></Footer>
    </>
}
