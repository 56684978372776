import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';


const ScrollToTop = () =>{

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
      console.log('test')
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)

  return <>
        <Button onClick={scrollTop} className="scrollTop" title="Go to top" style={{ display: showScroll ? 'flex' : 'none'}}><i class="fa  fa-chevron-up" aria-hidden="true"></i></Button>
  </>
}

export default ScrollToTop;