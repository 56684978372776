import React, {Component} from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


// const CustomToolbar = () => (
//     <div id="toolbar">
//       <button className="ql-list" value="bullet" />
//     </div>
//   );

class AddJob extends Component {

    constructor(){

        super();

        this.state = ({
            title : '',
            description : ''            
        });
        
    }

    onFormSubmit = async (e) => {
        e.preventDefault();
       
        const newJob = {
            title: this.state.title,
            description: this.state.description
        }

        const response = await axios.post('/admin-panel/jobs/add',
            newJob, 
            { headers: { 'x-auth-token': localStorage.getItem('auth-token')}}
        );
        
        //console.log(response.data);
       
        this.setState({
            title : '',
            description: ''
        });
        //window.location = '/admin-panel';
    }

    render(){
        
        return (
            <div>
                <h3>Add a new Job</h3>

                <Form onSubmit={this.onFormSubmit}>
                    <Form.Row className="align-items-center">
                        <Col xs={12} className="my-1">
                            <Form.Group>
                                <Form.Label htmlFor="inlineFormInputName" srOnly>
                                    Job title
                                </Form.Label>
                                <Form.Control id="inlineFormInputName" placeholder="Job title" 
                                    value={this.state.title}
                                    onChange={(e)=> this.setState({title : e.target.value})} required />
                            </Form.Group>
                        </Col>
                        <Col xs={12} className="my-1">
                            <Form.Group>
                                <ReactQuill value={this.state.description}
                                    onChange={(value) => this.setState({description : value})}  className="editor" required />
                             </Form.Group>
                        </Col>
                        <Col xs={12} className="my-1">
                             <Form.Group>
                                <Button type="submit">Add</Button>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Form>
               
            </div>
        )
    }
}

export default AddJob;