import React from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import GoogleMaps from 'simple-react-google-maps';
import HCaptcha from '@hcaptcha/react-hcaptcha';


class Contact extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
          name: '',
          email: '',
          message: '',
          isSent: false,
          success: false,
          successMsg: '',
          token: ''
        };
    }

      onInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
      };
    
      onFormSubmit = async (event) => {
        event.preventDefault();   
    
        const newEmail = {
          token: this.state.token,
          name: this.state.name,
          email: this.state.email,
          message: this.state.message
        }
    
        try {
          const response = await axios.post('/api/send-email', newEmail);
          this.setState({
            successMsg: 'Thank you, your message has been sent.',
            success: true
          });
        } catch (err) {
          console.log(err);
          this.setState({successMsg: 'There has been an error. Please try again later'});
        }
        
        this.setState({
          token: '',
          name : '',
          email : '',
          message : '',
          isSent: true
        });
          
      };

    render(){

        const isSent = this.state.isSent;
        const alertType = this.state.success ? 'alert alert-success' : 'alert alert-danger';
        
        return <>
        <Container fluid id="contact" className="py-5">
            <Container>
                <Row>
                    <Col xs="12" lg="6" className="py-4">
                    {isSent && 
                        <div className={alertType} role="alert">
                            {this.state.successMsg}
                        </div>
                    } 
                        <Form onSubmit={this.onFormSubmit}>
                            <Form.Group controlId="name">
                                <Form.Label>Your Name (required)</Form.Label>
                                <Form.Control
                                    name="name"
                                    type="text"
                                    value={this.state.name}
                                    onChange={this.onInputChange}
                                    required/>
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Label>Your Email (required)</Form.Label>
                                <Form.Control 
                                    name="email"
                                    type="email"
                                    value={this.state.email}
                                    onChange={this.onInputChange}
                                    required/>
                            </Form.Group>
                            <Form.Group controlId="message">
                                <Form.Label>Your Message</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    rows="6" 
                                    name='message'
                                    value={this.state.message}
                                    onChange={this.onInputChange}
                                    aria-label="message" />
                            </Form.Group>
                            <Form.Group>
                                <HCaptcha
                                  sitekey="99685381-468e-455c-b8a3-e3996599cc8d"
                                  onVerify={token => this.setState({token})}
                                  onExpire={e => this.setState({token: ''})}
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                SEND
                            </Button>
                        </Form>
                    </Col>
                    <Col xs="12" lg="6" className="map-container py-4">            
                        <GoogleMaps
                        apiKey={process.env.REACT_APP_API_KEY}
                        style={{ height: "440px", width: "100%" }}
                        zoom={15}
                        center={{ lat: 43.650231, lng: -79.383237 }}
                        markers={{ lat: 43.650231, lng: -79.383237 }} //optional
                        />

                        <p className="my-1">
                        120 Adelaide St W, Suite 2500, Toronto, ON, M5H 1T1
                        </p>
                        <p className="my-1">Phone: (416) 319-2586</p>
                   
                    </Col>
                </Row>
            </Container>
        </Container>
        </>
    }   
}

export default Contact;