import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Footer() {
    return  <Container fluid className="footer px-0">
                <Container fluid className="parallax">
                    <Container className="py-5">
                        <Row>
                            <Col xs="10" lg="6">
                                <h1>Lexington Innovations</h1>
                                <p>Unique technology solutions for the capital-markets industry.</p>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container fluid className="footer-copyright">
                    <Container className="py-5 text-center">
                        <Row className="justify-content-center">
                            <Col>
                                <p>© Copyright 2016 - {new Date().getFullYear()}&nbsp;&nbsp;Lexington Innovations&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;website produced by <a href="https://www.lexingtoninnovations.ca/" target="_blank" rel="noopener noreferrer">www.lexingtoninnovations.ca</a></p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col className="footer-social p-3">
                                <a className="px-1" href="https://www.linkedin.com/company/10364410" target="_blank" rel="noopener noreferrer" title="linkedin"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                <a className="px-1" href="https://twitter.com/lexingtoninnov" target="_blank" rel="noopener noreferrer" title="twitter"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                            </Col>
                        </Row>
                    </Container>
                </Container>
     </Container>
}
